<script src="../router.js"></script>
<template>
  <div class="search-inquiry-form linde-font">
    <v-card>
      <v-flex class="text-md-left">
        <v-btn
            small
            flat
            class="normalize-font"
            color="primary"
            to="/"
        >
          <v-icon small>arrow_back</v-icon>
          zurück zur Suche
        </v-btn>
      </v-flex>
      <v-card-title>
        <span class="title linde-color"> Suchauftrag stellen </span>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <p class="subheading">
            Ihre Angaben
          </p>
          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">
            Ansprechpartner
          </div>
          <v-layout row wrap>
            <v-flex md4 sm6 xs12>
              <v-text-field class="pr-3" :rules="companyOrganizationRules"
                            label="Firma*"
                            v-model="companyOrganization"
                            required></v-text-field>
            </v-flex>

            <v-flex md3 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Straße Hausnummer" v-model="address"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="addressRules" label="Straße Hausnummer*" v-model="address"
                            required></v-text-field>
            </v-flex>
            <v-flex md2 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Postleitzahl" v-model="zipCode"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="zipCodeRules" label="Postleitzahl*" v-model="zipCode"
                            required></v-text-field>
            </v-flex>
            <v-flex md3 sm6 xs12>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Ort" v-model="place"
                            required></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="placeRules" label="Ort*" v-model="place"
                            required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex md4>
              <v-text-field class="pr-3" label="Vor- & Nachname*" :rules="nameRules" v-model="name"
                            required></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field v-if="isLoggedIn" class="pr-3" label="Telefon" v-model="phone"></v-text-field>
              <v-text-field v-if="!isLoggedIn" class="pr-3" :rules="phoneRules" label="Telefon*" v-model="phone"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field class="pr-3" :rules="emailRules" label="E-Mail-Adresse*" v-model="email"></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex md4>
              <v-text-field  v-if="isLoggedIn" class="pr-3" :rules="budgetRules" label="Budget*" v-model="budget"></v-text-field>
              <v-text-field  v-if="!isLoggedIn" class="pr-3" label="Budget" v-model="budget"></v-text-field>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>

          <div class="mb-1 body-2 linde-bold">
            Angaben zum Gerät
          </div>

          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select v-if="isLoggedIn"
                  :items="['Gas', 'Diesel', 'Elektro']"
                  v-model="propulsion"
                  label="Antrieb (Elektro, Gas, Diesel)"
              ></v-select>
              <v-select v-if="!isLoggedIn"
                  :items="['Gas', 'Diesel', 'Elektro']"
                  v-model="propulsion"
                  label="Antrieb (Elektro, Gas, Diesel)*"
                  :rules="propulsionRules"
              ></v-select>
            </v-flex>
            <v-flex md4 sm6 xs12>
              <v-text-field class="pr-3" label="Typ" v-model="type"></v-text-field>
            </v-flex>
            <v-flex class="mr-3">
              <v-text-field v-if="isLoggedIn"
                  v-model="loadCapacity"
                  label="Tragfähigkeit (kg)"
              ></v-text-field>
              <v-text-field v-if="!isLoggedIn"
                  v-model="loadCapacity"
                  label="Tragfähigkeit (kg)*"
                  :rules="requiredRules"
                  required
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select v-if="isLoggedIn"
                  :items="constructionYearSpan"
                  v-model="constructionYear"
                  label="Baujahr ab"
              ></v-select>
              <v-select v-if="!isLoggedIn"
                  :items="constructionYearSpan"
                  v-model="constructionYear"
                  label="Baujahr ab*"
                  :rules="constructionYearRules"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select v-if="isLoggedIn"
                  :items="['2500', '5000', '10000']"
                  v-model="operatingHours"
                  label="Betriebsstunden (h) bis"
              ></v-select>
              <v-select v-if="!isLoggedIn"
                  :items="['2500', '5000', '10000']"
                  v-model="operatingHours"
                  label="Betriebsstunden (h) bis*"
                  :rules="requiredRules"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3">
              <v-select
                  :items="['Beliebig', 'Ja', 'Nein']"
                  v-model="fullCabin"
                  label="Vollkabine"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex class="mr-3" md4 sm6 xs12>
              <v-select
                  :items="constants.mastTypes"
                  v-model="mastType"
                  label="Hubmast"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3" md4>
              <v-select
                  :items="liftingHeightSpan"
                  v-model="liftingHeight"
                  label="Hubhöhe (mm)"
              ></v-select>
            </v-flex>
            <v-flex class="mr-3">
              <v-text-field
                  v-model="height"
                  label="Bauhöhe (mm)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="mr-3" md4 sm4 xs12>
              <v-select
                  :items="['Beliebig', 'Einzel-Zusatzhydraulik', 'Doppel-Zusatzhydraulik', '3. Zusatzhydraulik']"
                  v-model="additionalHydraulic"
                  label="Hydraulik"
              ></v-select>
            </v-flex>
            <v-flex md4 sm6 xs12>
              <v-text-field
                  v-model="attachmentUnit"
                  label="Anbaugerät"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="mr-3">
              <v-textarea label="Sonstiges" v-model="other"></v-textarea>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">DSGVO & CO.</div>
          <div>
            <v-checkbox
                class="mb-0"
                style="font-size:14px"
                v-model="dataConfirmation"
                :rules="[(v) => !!v || 'Bitte aktzeptieren zum fortfahren!']"
                color="primary"
            >
              <template v-slot:label>
                <div>
                  Die
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                          target="_blank"
                          href="/#/dsgvo"
                          @click.stop
                          v-on="on"
                      >
                        Datenschutzerklärung
                      </a>
                    </template>
                    Im neuen Fenster öffnen
                  </v-tooltip>
                  habe ich zur Kenntnis genommen.
                </div>
              </template>
            </v-checkbox>
          </div>

          <div class="text-md-right text-sm-right text-xs-right">
            <v-btn class="normalize-font" color="primary" v-on:click="submit">
              Absenden
            </v-btn>
          </div>
          <v-divider class="my-3"></v-divider>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-flex class="text-md-left">
          <slot name="action"></slot>
        </v-flex>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters, mapState} from "vuex";
import CONSTSANTS from "../utils/constants";
import content from "@/content/info";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SearchInquiryForm",
  data() {
    return {
      constants: CONSTSANTS,
      requiredRules: [ v => !!v || "Bitte geben Sie einen Wert ein."],
      valid: false,
      name: "",
      nameRules: [v => !!v || "Vor- & Nachname ist erforderlich"],
      companyOrganization: "",
      companyOrganizationRules: [
        v => !!v || "Unternehmen/Organisation ist erforderlich"
      ],
      address: "",
      addressRules: [
        v => !!v || "Straße & Hausnummer ist erforderlich",
        v => /\b\w+(.)\s+\d+\b/.test(v) || "Ungültige Straße & Hausnummer"
      ],
      zipCode: "",
      zipCodeRules: [
        v => !!v || "Postleitzahl ist erforderlich",
        v =>
            /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) ||
            "Ungültige Postleitzahl"
      ],
      place: "",
      placeRules: [v => !!v || "Ort ist erforderlich"],
      email: "",
      emailRules: [
        v => !!v || "E-mail ist erforderlich",
        v =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "Ungültige E-mail"
      ],
      phone: "",
      phoneRules: [v => !!v || "Telefon ist erforderlich"],
      budget: "",
      budgetRules: [v => !!v || "Budget ist erforderlich"],
      propulsion: "",
      propulsionRules: [v => !!v || "Antrieb ist erforderlich"],
      type: "",
      loadCapacity: "",
      loadCapacitiesRules: [v => !!v || "Tragfähigkeit ist erforderlich"],
      constructionYear: "",
      constructionYearRules: [v => !!v || "Baujahr ist erforderlich"],
      operatingHours: "",
      fullCabin: "",
      mastType: "",
      liftingHeight: "",
      height: "",
      additionalHydraulic: "",
      attachmentUnit: "",
      other: "",
      dataConfirmation: false,
      agbString: `
      Die Datenschutzerklärung habe ich zur Kenntnis genommen.
      `,
      constructionYearSpan: [],
      liftingHeightSpan: []
    };
  },
  mounted() {
    this.generateConstructionYearSpan();
    this.generateLiftingHeightSpan();
    this.fillInBasicInformationForLoggedIn();
  },
  methods: {
    ...HelperFunctions,
    submit(e) {
      this.$refs.form.validate();
      e.preventDefault();
      if (this.valid) {
        this.loading(true);
        axios
            .post(this.$apiURL + "searchInquiries/mail", {
              name: this.name,
              companyOrganization: this.companyOrganization,
              address: this.address,
              zipCode: this.zipCode,
              place: this.place,
              email: this.email,
              phone: this.phone,
              budget: this.budget,
              propulsion: this.propulsion,
              type: this.type,
              loadCapacity: this.loadCapacity,
              constructionYear: this.constructionYear,
              operatingHours: this.operatingHours,
              fullCabin: this.fullCabin,
              mastType: this.mastType,
              liftingHeight: this.liftingHeight,
              height: this.height,
              additionalHydraulic: this.additionalHydraulic,
              attachmentUnit: this.attachmentUnit,
              other: this.other,
              dataConfirmation: this.dataConfirmation
            })
            .then(() => {
              //Scrolls to top when view is displayed
              window.scrollTo(0, 0);
              this.loading(false);
              this.initiateSuccessInfo("Suchanfrage erfolgreich versendet!")
              this.resetForm();
              this.$router.push({path: '/'});

            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben prüfen!")
            });
      }
    },
    onClickShowInfo(type) {
      this.infoTitle = content[type].title;
      this.infoText = content[type].info;

      if (type === "agb") {
        axios.get("/agb.html").then(response => this.infoText = response.data);
      }

      this.privacy = !this.privacy;
    },
    generateConstructionYearSpan() {
      const constructionYears = [{text: "Beliebig", value: undefined}];
      const max = new Date(Date.now()).getFullYear();
      const min = new Date('1970').getFullYear();
      for (let el = max; el >= min; el--)
        constructionYears.push({text: el, value: el});
      this.constructionYearSpan = constructionYears;
    },
    generateLiftingHeightSpan() {
      const liftingHeight = [...this.constants.liftingHeights];
      // 10000...12000
      const values = [
        {
          text: "10.500 mm",
          value: 10500
        },
        {
          text: "11.000 mm",
          value: 11000
        },
        {
          text: "11.500 mm",
          value: 11500
        },
        {
          text: "12.000 mm",
          value: 12000
        },
      ];
      liftingHeight.push(...values);
      this.liftingHeightSpan = liftingHeight;
    },
    resetFormInput() {
      this.name = "";
      this.companyOrganization = "";
      this.address = "";
      this.zipCode = "";
      this.place = "";
      this.email = "";
      this.phone = "";
      this.budget = "";
      this.propulsion = "";
      this.type = "";
      this.loadCapacity = "";
      this.constructionYear = "";
      this.operatingHours = "";
      this.fullCabin = "";
      this.mastType = "";
      this.liftingHeight = "";
      this.height = "";
      this.additionalHydraulic = "";
      this.attachmentUnit = "";
      this.other = "";
      this.dataConfirmation = false;
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    abbort() {
      this.resetForm();
      this.resetValidation();
      this.setDialogStatus();
    },
    fillInBasicInformationForLoggedIn() {
      if(this.isLoggedIn) {
        this.companyOrganization = this.user.company;
        this.name = this.user.firstName + ' ' + this.user.lastName;
        this.email = this.user.email;
      }
    },
  },
  computed: {
    ...mapState([
      "user"
    ]),
    ...mapGetters(["loginState"]),
    isLoggedIn() {
      return this.loginState;
    },
  }
};
</script>

<style>
label.v-label {
  font-size: 14px;
}
</style>
