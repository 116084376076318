<template>
  <div class="inquiry-form linde-font">
    <v-card>
      <v-flex class="text-md-left">
        <slot name="back"></slot>
      </v-flex>
      <v-card-title>
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <p class="subheading">
            Ihr bevorzugter Kontaktweg
          </p>
          <v-divider class="my-3"></v-divider>
          <v-layout row wrap>
            <v-flex class="pt-4" md5 sm12 xs12>
              Angebot erhalten*
            </v-flex>
            <v-flex>
              <v-radio-group v-model="receiveOfferAs" row>
                <v-radio label="per Email" value="email"></v-radio>
                <v-radio label="per Post" value="print"></v-radio>
                <v-radio label="per Fax" value="fax"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>
          <p class="subheading">
            Finanzierung/Mietkauf
          </p>
          <v-divider class="my-3"></v-divider>

          <v-layout row wrap>
            <v-flex>
              <v-radio-group v-model="customerIntend" row>
                <v-radio label="Kauf" value="buy"></v-radio>
                <v-radio label="Leasing" value="lease"></v-radio>
                <v-radio label="Finanzierung (Mietkauf)" value="finance"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>

          <v-layout v-if="customerIntend === 'finance'">
            <v-flex md2>
              <v-select
                  class="pr-3"
                  v-model="runTime"
                  :items="fundableRuntimes"
                  label="Laufzeit (Monate)"
                  placeholder="Bitte wählen"
                  required
              ></v-select>
            </v-flex>
            <v-flex md2>
              <v-select
                  class="pr-3"
                  v-model="residual"
                  :items="fundableResiduals"
                  label="Restwert (Prozent)"
                  placeholder="Bitte wählen"
                  required
              ></v-select>
            </v-flex>
          </v-layout>

          <v-divider class="my-3"></v-divider>
          <p class="subheading">
            Ihre Angaben
          </p>
          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">
            Kontaktdaten
          </div>
          <v-layout>
            <v-flex md5>
              <v-select
                  class="pr-3"
                  v-model="salutation"
                  :items="['Herr', 'Frau']"
                  label="Anrede*"
                  placeholder="Bitte wählen"
                  :rules="salutationRules"
                  required
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md5>

              <v-text-field class="pr-3" label="Vorname*" :rules="nameRules" v-model="name"
                            required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field class="pr-3" label="Nachname*" :rules="lastnameRules" v-model="lastname"
                            required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md5>
              <v-text-field class="pr-3" :rules="companyOrganizationRules"
                            label="Unternehmen/Organisation*"
                            v-model="companyOrganization"
                            required></v-text-field>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-flex md5>
              <v-text-field class="pr-3" :rules="addressRules" label="Straße Hausnummer*" v-model="address"
                            required></v-text-field>
            </v-flex>
            <v-flex md3>
              <v-text-field class="pr-3" :rules="zipCodeRules" label="Postleitzahl*" v-model="zipCode"
                            required></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field class="pr-3" :rules="placeRules" label="Ort*" v-model="place"
                            required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md5>
              <v-select
                  class="pr-3"
                  v-model="country"
                  :rules="countryRules"
                  :items="['Deutschland', 'Österreich', 'Schweiz']"
                  label="Land*"
                  required
              ></v-select>
            </v-flex>
            <v-flex>
              <v-text-field class="pr-3" :rules="emailRules" label="E-Mail-Adresse*" v-model="email"
                            required></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md5>
              <v-text-field class="pr-3" :rules="phoneRules" label="Telefon*" v-model="phone"
                            required></v-text-field>
            </v-flex>
            <v-flex>
              <v-text-field class="pr-3" label="Fax" v-model="fax" required></v-text-field>
            </v-flex>
          </v-layout>
          <v-divider class="my-3"></v-divider>
          <v-divider class="my-3"></v-divider>

          <span class="subheading">
                Anmerkungen (optional)
            </span>
          <v-textarea label="Ihre Anmerkungen" v-model="remarks"></v-textarea>
          <v-divider class="my-3"></v-divider>
          <v-divider class="my-3"></v-divider>
          <div class="mb-1 body-2 linde-bold">Angaben senden</div>
          <p class="body-2">Schicken Sie uns nun Ihre Angaben zu. Sie erhalten nach dem erfolgreichen Versand
            eine Anmeldebestätigung per E-Mail.*</p>
          <div>
            <v-checkbox
                class="mb-0"
                style="font-size:14px"
                :label="agbString"
                v-model="dataConfirmation"
                :rules="[(v) => !!v || 'Bitte aktzeptieren zum fortfahren!']"
                color="primary"
            ></v-checkbox>
          </div>

          <div class="text-md-right text-sm-right text-xs-right">
            <v-btn class="normalize-font" color="primary" v-on:click="submit">
              Absenden
            </v-btn>
          </div>
          <v-divider class="my-3"></v-divider>
          <v-divider class="my-3"></v-divider>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-flex class="text-md-left">
          <slot name="action"></slot>
        </v-flex>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import constants from "@/utils/constants";
import helperFunctions from "@/utils/helper-functions";

export default {
  name: "InquiryForm",
  data() {
    return {
      valid: false,
      fundableRuntimes: constants.runTimes,
      fundableResiduals: constants.residuals,
      receiveOfferAs: "email",
      customerIntend: this.assignedCustomerIntend || "buy",
      residual: this.assignedResidual || 10,
      runTime: this.assignedRunTime || 24,
      salutation: "",
      salutationRules: [v => !!v || "Anrede ist erforderlich"],
      name: "",
      nameRules: [v => !!v || "Vorname ist erforderlich"],
      lastname: "",
      lastnameRules: [v => !!v || "Nachname ist erforderlich"],
      companyOrganization: "",
      companyOrganizationRules: [
        v => !!v || "Unternehmen/Organisation ist erforderlich"
      ],
      address: "",
      addressRules: [
        v => !!v || "Straße Hausnummer ist erforderlich",
        v => /\b\w+(.)\s+\d+\b/.test(v) || "Ungültige Straße Hausnummer"
      ],
      zipCode: "",
      zipCodeRules: [
        v => !!v || "Postleitzahl ist erforderlich",
        v =>
            /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/.test(v) ||
            "Ungültige Postleitzahl"
      ],
      place: "",
      placeRules: [v => !!v || "Ort ist erforderlich"],
      country: "",
      countryRules: [v => !!v || "Land ist erforderlich"],
      email: "",
      emailRules: [
        v => !!v || "E-mail ist erforderlich",
        v =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "Ungültige E-mail"
      ],
      phone: "",
      phoneRules: [v => !!v || "Telefon ist erforderlich"],
      fax: "",
      remarks: "",
      dataConfirmation: false,
      agbString: `Ich bin damit einverstanden, dass meine Daten gespeichert und von der Linde Material Handling Rhein-Ruhr GmbH & Co. KG zu Informationszwecken genutzt werden. Eine Weiterleitung an Dritte ist ausgeschlossen.`
    };
  },
  props: {
    equipment: {},
    assignedRunTime: 0,
    assignedResidual: 0,
    assignedCustomerIntend: ""
  },
  methods: {
    ...helperFunctions,
    submit(e) {
      this.$refs.form.validate();
      e.preventDefault();
      if (this.valid) {
        this.loading( true);
        axios
            .post(this.$apiURL + "Inquiries/mail", {
              receiveOfferAs: this.receiveOfferAs,
              customerIntend: this.customerIntend,
              runTime: this.runTime,
              residual: this.residual,
              salutation: this.salutation,
              name: this.name,
              lastname: this.lastname,
              companyOrganization: this.companyOrganization,
              address: this.address,
              zipCode: this.zipCode,
              place: this.place,
              country: this.country,
              email: this.email,
              phone: this.phone,
              fax: this.fax,
              remarks: this.remarks,
              equipmentId: this.equipment.equipmentId
            })
            .then(() => {
              //Scrolls to top when view is displayed
              window.scrollTo(0, 0);
              this.loading(false);
              this.$store.dispatch("showSnackbar", {
                color: "success",
                message: "Anfrage erfolgreich versendet!"
              });
              this.resetFormInput();
              this.$router.push({path: '/result'});

            })
            .catch(() => {
              this.$store.dispatch("showSnackbar", {
                color: "error",
                message: "Bitte Eingaben prüfen!"
              });
            });
      }
    },
    resetFormInput() {
      this.receiveOfferAs = "email";
      this.salutation = "";
      this.name = "";
      this.lastname = "";
      this.companyOrganization = "";
      this.address = "";
      this.zipCode = "";
      this.place = "";
      this.country = "";
      this.email = "";
      this.phone = "";
      this.fax = "";
      this.remarks = "";
      this.dataConfirmation = false;
    }
  }
};
</script>

<style>
label.v-label {
  font-size: 14px;
}
</style>
