const content = {
  impressum: {
    info: "loaded from LEGAL.HTML file",
    title: "Impressum"
  },
  agb: {
    info: "loaded from AGBS.HTML file",
    title: "AGB"
  },
  datenschutz: {
    info: "loaded from PRIVACY.HTML file",
    title: "Datenschutz"
  }
};

export default content;
