<template>
  <div class="trade-in-management">
    <v-data-table
        style="padding: 10px !important;"
        :headers="headers"
        :items="tradeIns"
        :rows-per-page-items="[5,10,25, {'text': 'Alle', 'value':-1}]"
        rows-per-page-text="Zeilen pro Seite"
        class="fixed-actions-table"

    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{ mapStatus(props.item.status) }}</td>
        <td class="text-xs-left">{{
            props.item.createdAt | formatDate
          }}
        </td>
        <td class="text-xs-left">{{ props.item.companyOrganization }}</td>
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">
          {{ props.item.address ? props.item.address + ", " + props.item.zipCode + " " + props.item.place : "-" }}
        </td>
        <td class="text-xs-left">{{ props.item.phone }}</td>
        <td class="text-xs-left">{{ props.item.email }}</td>
        <td class="text-xs-left actions-cell">
          <v-btn right icon small flat color="primary" @click="showTradeIn(props.item.id)">
            <v-icon>notes</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <!-- trade-in modal -->
    <TradeInInformation></TradeInInformation>
  </div>
</template>

<script>
import TradeInInformation from "@/components/TradeInInformation";
import {EventBus} from "../../event-bus";
import TradeInService from "@/services/trade-in.service";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "TradeInManagement",
  data() {
    return {
      showTradeInForm: false,
      headers: [
        {
          text: "Status", value: "status",
          sortable: true,
        },
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "createdAt"
        },
        {text: "Firma", value: "companyOrganization"},
        {text: "Vor- & Nachname", value: "name"},
        {text: "Adresse", value: "address"},
        {text: "Telefon", value: "phone"},
        {text: "E-Mail", value: "email"},
        {text: "", value: "spacer1"}
      ],
      tradeIns: [],
      selectedTradeIn: "",
      counter: 0
    };
  },
  components: {
    TradeInInformation
  },
  mounted() {
    this.getTradeIns((this.$store.state.accessToken
        ? this.$store.state.accessToken.id
        : null));
  },
  methods: {
    ...HelperFunctions,
    showTradeIn(tradeInId) {
      this.selectedTradeIn = this.tradeIns.find(ti => ti.id === tradeInId);
      EventBus.$emit("openTradeInDialog", true);
      EventBus.$emit("onSelectTradeIn", this.selectedTradeIn);
      EventBus.$on("onCloseTradeInDialog", () => {
        this.getTradeIns((this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null));
      });
    },
    getTradeIns() {
      this.counter += 1;
      TradeInService.getTradeIns(
          (this.$store.state.accessToken
              ? this.$store.state.accessToken.id
              : null)
      )
          .then(response => {
            this.tradeIns = response.data;
          })
          .catch(error => {
            throw error;
          });
    }
  }
};
</script>

<style scoped>
</style>
