<template>
  <div class="create-return-truck-form">
    <v-dialog
        v-model="createDialog"
        width="900"
        persistent
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3"
          >Return Truck anlegen
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-stepper v-model="e1" ref="stepper">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1">Return Truck anlegen</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2">Konfiguration hinzufügen</v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card
                    elevation="0"
                >
                  <v-form
                      id="return-truck-form"
                      ref="form"
                      v-model="valid"
                      lazy-validation
                  >
                    <v-layout>
                      <v-flex class="mr-3" md4 sm4 xs12>
                        <v-text-field
                            v-model="createdReturnTruck.aProdId"
                            label="Anbieter-Prod-Nr.*"
                            :rules="[rules.required]"
                            required
                        ></v-text-field>
                      </v-flex>
                      <v-flex class="mr-3" md4 sm4 xs12>
                        <v-text-field
                            v-model="createdReturnTruck.chassisNumber"
                            label="Fahrgestell-Nr.*"
                            :rules="[rules.required]"
                            required
                        ></v-text-field>
                      </v-flex>
                      <v-flex md4 sm4 xs12>
                        <v-select
                            :items="statusItems"
                            v-model="createdReturnTruck.status"
                            label="Status"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <!-- Line type & construction type & serial number & mast type -->
                    <v-layout>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-text-field
                            v-model="createdReturnTruck.type"
                            label="Ausführung"
                        ></v-text-field>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-combobox
                            clearable
                            :items="constructionTypes"
                            v-model="createdReturnTruck.cType"
                            label="Bauart"
                            @input.native="e => createdReturnTruck.cType = e.target.value"
                        ></v-combobox>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-combobox
                            :items="manufacturers"
                            v-model="createdReturnTruck.manufacturer"
                            label="Hersteller"
                            @input.native="e => createdReturnTruck.manufacturer = e.target.value"
                        ></v-combobox>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-checkbox
                            v-model="createdReturnTruck.inStock"
                            label="Im Bestand"
                            color="primary"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                    <!-- Line construction year & load capacity & operating hours & price -->
                    <v-layout>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-select
                            :items="generateConstructionYearSpan()"
                            v-model="constructionYear"
                            label="Baujahr"
                        ></v-select>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-text-field
                            v-model="createdReturnTruck.operatingHours"
                            label="Betriebsstunden (h)"
                        ></v-text-field>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-select
                            :items="secondLifeDecisionItems"
                            v-model="createdReturnTruck.secondLifeDecision"
                            label="Weitere Verwendung"
                        ></v-select>
                      </v-flex>
                      <v-flex class="mr-3" md3 sm3 xs12>
                        <v-checkbox
                            v-model="createdReturnTruck.costEstimate"
                            label="Kostenvoranschlag"
                            color="primary"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                    <!-- Line images real -->
                    <v-layout>
                      <v-flex md12>
                        <!-- multiple select of real images -->
                        <v-layout v-if="createdReturnTruck.images" row wrap>
                          <v-flex
                              md2
                              v-for="(image, index) of createdReturnTruck.images"
                              :key="image.imageId"
                          >
                            <v-layout justify-space-between class="mb-2" row wrap>
                              <v-flex class="pl-5" md2 v-if="image.image ">
                                <v-img
                                    v-if="!createdReturnTruck.id || !image.returnTruckId"
                                    class="mb-2"
                                    :src="image.url"
                                    width="55"
                                    height="55"
                                >
                                </v-img>
                                <v-img
                                    v-else
                                    class="mb-2"
                                    :src="appendBaseUrl(image.image)"
                                    width="55"
                                    height="55"
                                ></v-img>
                              </v-flex>
                            </v-layout>
                            <v-layout class="text--left" row wrap>
                              <v-flex md3>
                                <v-btn
                                    class="mt-0 pt-0"
                                    small
                                    icon
                                    flat
                                    @click="
                            removeImageFromList(
                              image.imageId ? image.imageId : '',
                              index,
                              image
                            )
                          "
                                >
                                  <v-icon color="primary" small>delete</v-icon>
                                </v-btn>
                              </v-flex>
                              <v-flex class="my-0 py-0 pt-1" md9>
                                {{ formatImageName(image.image) }}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>

                        <v-btn
                            color="primary"
                            class="normalize-font"
                            flat
                            @click="pickFile(false)"
                        >
                          <v-icon small>attach_file</v-icon>
                          Bilder hinzufügen
                        </v-btn>
                        <input
                            type="file"
                            style="display: none"
                            ref="images"
                            accept="image/*"
                            @change="onMultipleFilesPicked"
                            multiple
                        />
                      </v-flex>
                    </v-layout>
                  </v-form>
                  <v-card-actions >
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        @click.native="createReturnTruck"
                    >
                      Weiter
                    </v-btn>

                    <v-btn @click="cancel" flat>Abbrechen</v-btn>
                  </v-card-actions>
                </v-card>

              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card
                    elevation="0"
                >
                  <v-layout v-if="createdReturnTruck.id">
                    <v-flex md12>
                      <p>Bitte fügen Sie eine Konfigurationsdatei vom Typ .docx hinzu.</p>
                      <v-btn
                          color="primary"
                          class="normalize-font"
                          flat
                          @click="uploadFiles"
                      >
                        <v-icon small>attach_file</v-icon>
                        Konfigurationsliste hinzufügen
                      </v-btn>
                      <input
                          type="file"
                          style="display: none"
                          ref="text"
                          @change="onFileChange"
                      />
                      {{ uploadStatus }}
                    </v-flex>
                  </v-layout>
                  <v-divider style="margin-bottom: 10px"
                             v-if="configurations && configurations.length > 0"></v-divider>
                  <v-layout v-if="configurations && configurations.length > 0">
                    <v-flex md12>
                      Konfigurationsliste
                    </v-flex>
                  </v-layout>
                  <v-layout v-if="configurations && configurations.length > 0">
                    <v-flex md12>
                      <v-data-table style="padding: 0 15px!important;"
                                    :headers="configurationHeaders"
                                    :items="configurations"
                                    :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
                                    rows-per-page-text="Zeilen pro Seite"
                                    @update:page="onPageUpdate"
                                    @update:items-per-page="onItemsPerPageUpdate"
                                    class="elevation-0 lower-padding"
                      >
                        <template slot="items" slot-scope="props">
                          <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.code }}</td>
                          <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.category }}</td>
                          <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.description }}</td>
                          <td class="text-xs-left" style="padding: 0 15px!important;">{{ props.item.comment }}</td>
                          <td class="text-xs-left" style="padding: 0 15px!important;">
                            <v-select
                                :items="configStatusItems"
                                v-model="props.item.status"
                                @change="setConfigStatus(props.item.id, props.item.status, props.item.comment)"
                                label="Status"
                            ></v-select>
                          </td>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="patchReturnTruck"
                    >
                      Speichern
                    </v-btn>

                    <v-btn @click="cancel" flat>Abbrechen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {EventBus} from "@/event-bus";

import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";

import CONSTANTS from "../../utils/constants"
import HelperFunctions from "@/utils/helper-functions";
import ReturnTruckService from "@/services/return-truck.service";
import ConfigurationService from "@/services/configuration.service";

let fds = new FormData(),
    fDs = [];

export default {
  name: "CreateReturnTruckForm",
  data() {
    return {
      valid: false,
      customImageMaxSize: 5,
      itemsPerPage: 10,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        rowsPerPage: 25,
      },
      uploadStatus: "",
      imageName: "",
      imageFile: "",
      imageRealNames: [],
      imagesFiles: [],
      images: [],
      image: "",
      deleteQueue: [],
      createDialog: false,
      isAdmin: false,
      configStatusItems: [{text: "OK", value: true}, {text: "NOK", value: false}],
      constants: CONSTANTS,
      manufacturers: CONSTANTS.manufacturer,
      statusItems: CONSTANTS.returnTruckStatusItems,
      secondLifeDecisionItems: CONSTANTS.secondLifeDecisionItems,
      constructionTypes: CONSTANTS.constructionTypes,
      configurationHeaders: [
        {
          text: "Code",
          align: "left",
          value: "code",
          width: "1%"
        },
        {text: "Kategorie", value: "category", width: "1%"},
        {text: "Beschreibung", value: "description", width: "1%"},
        {text: "Kommentar", value: "comment", width: "1%"},
        {text: "Status", value: "status", width: "1%"},
      ],
      configurations: [],
      createdReturnTruck: this.getEmptyReturnTruck(),
      response: "",
      infoColor: "primary",
      rules: {
        required: value => !!value || "Bitte geben Sie einen Wert ein."
      },
      e1: 0
    };
  },
  mounted() {
    this.getConstructionYearsSpan();
    this.isAdmin = this.user.isAdmin;
    EventBus.$on("openCreateReturnTruckDialog", this.setDialogStatus);
  },
  methods: {
    ...mapActions(["getConstructionYearsSpan"]),
    ...HelperFunctions,
    setConfigStatus(configId, status, comment ) {
      const index = this.createdReturnTruck.configurations.findIndex(c => c.id === configId)
      if (index > -1) {
        this.createdReturnTruck.configurations[index].status = status;
        this.createdReturnTruck.configurations[index].comment = comment;
      }
    },
    pickFile(single) {
      if (single) this.$refs.image.click();
      else this.$refs.images.click();
    },
    onMultipleFilesPicked(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        fDs.push({file: files[i], name: files[i]["name"]});
      }
      if (files[0] !== undefined) {
        for (const file of files) {
          this.imageRealNames.push(file.name);
          let i = {
            image: file.name,
            url: URL.createObjectURL(file)
          };
          this.createdReturnTruck.images.push(i);
          if (file.name.lastIndexOf(".") <= 0) {
            return;
          }
        }
      } else {
        this.images = [];
        this.imageRealNames = [];
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (!file) {
        this.uploadStatus = "No files selected.";
        return;
      }

      const fData = new FormData();
      // Append each file to FormData
      fData.append("docxFile", file);
      ReturnTruckService.uploadConfigurations(this.accessToken, this.createdReturnTruck.id, fData)
          .then(() => {
            this.uploadStatus = "Files uploaded successfully!";
            setTimeout(() => {
              this.uploadStatus = "";
              this.$refs.text.value = "";
            }, 1000);
          })
          .then(() => {
            ReturnTruckService.getReturnTruckById(this.accessToken, this.createdReturnTruck.id).then(response => {
              this.setCreatedReturnTruck(response.data);
            });
          })
          .catch(() => {
            this.uploadStatus = "Upload failed.";
          });
    },
    uploadFiles() {
      this.$refs.text.click()
    },
    patchReturnTruck: function (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        delete this.createdReturnTruck.createdAt;
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;

        // eslint-disable-next-line
        this.createdReturnTruck.imagesReal = null;
        this.loading(true);
        this.createdReturnTruck.updatedAt = new Date(Date.now());
        ReturnTruckService.patchReturnTruckById(
            this.accessToken,
            this.createdReturnTruck.id,
            this.createdReturnTruck
        )
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.id, fDs[i].file, fDs[i].name, "rTruck", "product");
                }
              }
            })
            .then(() => {
              this.initiateSuccessInfo("Return Truck update erfolgreich", true)
              EventBus.$emit("onCloseReturnTruckDialog", true);
              this.loading(false);
              this.setDialogStatus();
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte kontrollieren!")
              this.loading(false);
            });
      } else {
        this.initiateErrorInfo("Bitte kontrollieren!")
      }
    },
    createReturnTruck(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;
        // eslint-disable-next-line
        this.createdReturnTruck.image = null;
        this.createdReturnTruck.imagesReal = null;
        this.loading(true);
        ReturnTruckService.addReturnTruck(this.accessToken, this.createdReturnTruck)
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.id, fDs[i].file, fDs[i].name, "rTruck");
                }
              }
              this.createdReturnTruck = response.data;
            })
            .then(() => {
              this.initiateSuccessInfo("Return Truck wurde gespeichert", true)
              this.e1 = 2;
              this.$store.commit("loading", false);
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
              this.$store.commit("loading", false);
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
        this.$store.commit("loading", false);
      }
    },
    setDialogStatus() {
      this.e1 = 1;
      this.resetForm();
      this.resetCreatedReturnTruck();
      this.deleteQueue = [];
      this.createDialog = !this.createDialog;
    },
    cancel() {
      this.resetForm();
      this.resetValidation();
      EventBus.$emit("onCloseReturnTruckDialog", true);
      this.setDialogStatus();
    },
    setCreatedReturnTruck(returnTruck) {
      fds = new FormData();
      fDs = [];
      this.createdReturnTruck = "";
      this.createdReturnTruck = returnTruck;
      if (returnTruck) {
        ConfigurationService.getConfigurationsByReturnTruckId(this.accessToken, returnTruck.id)
            .then(response => {
              this.configurations = response.data;
              // this.createdReturnTruck.configurations = [...response.data];
              this.createdReturnTruck.images = this.createdReturnTruck.images || [];
              this.loading(false);
            });
      }
    },
    removeImageFromList(imageId, index, name) {
      if (imageId) {
        this.deleteQueue.push(imageId);
      }
      const indexToRemove = fDs.findIndex(img => img.name === name.image);
      fDs.splice(indexToRemove, 1);
      this.createdReturnTruck.images.splice(index, 1);
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetCreatedReturnTruck() {
      this.createdReturnTruck = this.getEmptyReturnTruck();
      this.configurations = [];
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onPageUpdate(newPage) {
      this.pagination.page = newPage;
    },
    onItemsPerPageUpdate(newItemsPerPage) {
      this.pagination.itemsPerPage = newItemsPerPage;
    }
  },
  computed: {
    ...mapState([
      "accessToken",
      "user"
    ]),
    constructionYear: {
      get: function () {
        if (this.createdReturnTruck.constructionYear)
          return Number(this.createdReturnTruck.constructionYear);
      },
      set: function (newValue) {
        this.createdReturnTruck.constructionYear = newValue;
      }
    }
  },
  components: {
    DescriptionValueDisplay,
  }
};
</script>

<style></style>

