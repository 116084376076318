<template>
  <div class="equipment-form">
    <v-dialog
        :value="dialog"
        width="900"
        persistent
        transition="dialog-bottom-transition"
        scrollable
    >
      <v-card v-if="isAdmin" tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3"
          >Equipment
            <span v-if="!selectedEquipment.equipmentId">erstellen</span>
            <span v-else>bearbeiten</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form
              id="equipment-form"
              v-model="valid"
              ref="form"
              lazy-validation
          >
            <!-- Line type & construction type & serial number & mast type -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.type"
                    label="Ausführung*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constructionTypes"
                    v-model="selectedEquipment.constructionType"
                    label="Bauart*"
                    :rules="[rules.required]"
                    required
                ></v-select>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.serialNumber"
                    label="Seriennummer*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.mastTypes"
                    v-model="selectedEquipment.mastType"
                    label="Masttyp"
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line lifting height & height & free lift & initial hub -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.liftingHeight"
                    label="Hubhöhe / h3 (mm)*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.height"
                    label="Bauhöhe / h1 (mm)*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.freeLift"
                    label="Freihub / h2 (mm)*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-checkbox
                    v-if="
                    selectedEquipment.mastType &&
                      constants.mastTypes.includes(
                        selectedEquipment.mastType
                      ) &&
                      selectedEquipment.mastType !== 'Beliebig'
                  "
                    class="mb-0"
                    style="font-size:14px"
                    label="Initialhub"
                    v-model="selectedEquipment.initialHub"
                    color="primary"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <!-- Line component types-->
            <v-layout>
              <v-flex class="">
                <v-select
                    :items="constants.componentTypes"
                    v-model="selectedEquipment.component"
                    label="Komponente/Typ"
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line drivers module -->
            <v-layout>
              <v-flex>
                <v-select
                    :items="constants.driversModules"
                    v-model="selectedEquipment.driversModule"
                    label="Fahrerschutzdach"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line height of cabin -->
            <v-layout>
            <v-flex md4 sm4 xs12 v-if="selectedEquipment.driversModule && selectedEquipment.driversModule.length > 0">
              <v-text-field
                  v-model="selectedEquipment.heightOfCabin"
                  label="Höhe Fahrerschutzdach / h6 (mm)"
                  :rules="[]"
                  required
              ></v-text-field>
            </v-flex></v-layout>
            <!-- Line additional hydraulics & driver seat -->
            <v-layout>
              <v-flex class="mr-3" md4 sm4 xs12>
                <v-select
                    :items="constants.additionalHydraulics"
                    v-model="selectedEquipment.additionalHydraulic"
                    label="Zusatzhydrauliksystem"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
              <v-flex class="" md8 sm8 xs12>
                <v-select
                    :items="constants.driversSeats"
                    v-model="selectedEquipment.driversSeat"
                    label="Fahrersitz"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line fork outside distance & fork carriage length & fork carriage thickness  -->
            <v-layout
                v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)">
              <v-flex class="mr-3" md4 sm4 xs12>
                <v-text-field
                    v-model="selectedEquipment.forkOutsideDistance"
                    label="Gabelträgeraußenabstand (mm)"
                    :rules="[]"
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md4 sm4 xs12>
                <v-text-field
                    v-model="selectedEquipment.forkCarriageLength"
                    label="Gabelträgerlänge (mm)"
                    :rules="[]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="" md4 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.forkCarriageThickness"
                    label="Gabelträgerdicke (mm)"
                    :rules="[]"
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- fork carrier -->
            <v-layout
                v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer'].includes(selectedEquipment.constructionType)">
              <v-flex>
                <v-select
                    :items="constants.forkCarrier"
                    v-model="selectedEquipment.forkCarrier"
                    label="Gabelträger/ Gabelträgeausführung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line drivers position equipment -->
            <v-layout>
              <v-flex>
                <v-select
                    :items="constants.driversPositionEquipments"
                    v-model="selectedEquipment.driversPositionEquipment"
                    label="Fahrerplatz- & Elektrische Ausrüstung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line operation and pedals & lights -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.operationAndPedals"
                    v-model="selectedEquipment.operationAndPedal"
                    label="Bedienung & Pedalerie"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.lights"
                    v-model="selectedEquipment.light"
                    label="Beleuchtung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line securities & tires -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.securities"
                    v-model="selectedEquipment.safety"
                    label="Sicherheit"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.tires"
                    v-model="selectedEquipment.tires"
                    label="Bereifung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line energy pack & attachment units -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.energyPacks"
                    v-model="selectedEquipment.energyPack"
                    label="Energiepaket"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.attachmentUnits"
                    v-model="selectedEquipment.attachmentUnit"
                    label="Anbaugerät"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line speed control -->
            <v-layout>
              <v-flex>
                <v-select
                    :items="constants.speedControl"
                    v-model="selectedEquipment.speedControl"
                    label="Geschwindigkeitsregelung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line colors & connect access control -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.colors"
                    v-model="selectedEquipment.color"
                    label="Farben"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.connectAccessControl"
                    v-model="selectedEquipment.connectAccessControl"
                    label="Connect / Zugangskontrolle"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line chassis -->
            <v-layout
                v-if="['Hochhubwagen', 'Niederhubwagen', 'Kommissionierer', 'Schlepper'].includes(selectedEquipment.constructionType)">
              <v-flex>
                <v-select
                    :items="constants.chassis"
                    v-model="selectedEquipment.chassis"
                    label="Chassis"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line surroundings -->
            <v-layout>
              <v-flex md12 sm12 xs12>
                <v-select
                    :items="constants.surroundings"
                    v-model="selectedEquipment.surrounding"
                    label="Umgebung"
                    multiple
                    attach
                    chips
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line additional construction type & provider product number & operation & propulsion -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.additionalConstructionType"
                    v-model="selectedEquipment.additionalConstructionType"
                    label="Beschreibung Zustand"
                ></v-select>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.providerProductNumber"
                    label="Anbieter Produkt-Nr.*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.operation"
                    label="Bedienung"
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="['Gas', 'Diesel', 'Elektro']"
                    v-model="selectedEquipment.propulsion"
                    label="Antrieb"
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line construction year & load capacity & operating hours & price -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constructionYearSpan"
                    v-model="constructionYear"
                    label="Baujahr"
                ></v-select>
              </v-flex>

              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.loadCapacity"
                    label="Tragfähigkeit (kg)*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.operatingHours"
                    label="Betriebsstunden (h)"
                ></v-text-field>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.price"
                    label="Preis*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- Line device status & location -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="constants.deviceStatus"
                    v-model="selectedEquipment.deviceStatus"
                    label="Zustand"
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-text-field
                    v-model="selectedEquipment.location"
                    label="Standort*"
                    :rules="[rules.required]"
                    required
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- Line availability & guarantee -->
            <v-layout>
              <v-flex class="mr-3" md6 sm6 xs12>
                <v-select
                    :items="availability"
                    v-model="selectedEquipment.availability"
                    label="Verfügbarkeit"
                ></v-select>
              </v-flex>
              <v-flex md6 sm6 xs12>
                <v-select
                    :items="constants.guaranteeOptions"
                    v-model="selectedEquipment.guarantee"
                    label="Gewährleistung"
                ></v-select>
              </v-flex>
            </v-layout>
            <!-- Line images real -->
            <v-layout>
              <v-flex md12>
                <!-- multiple select of real images -->
                <v-layout v-if="selectedEquipment.imagesReal" row wrap>
                  <v-flex
                      md2
                      v-for="(image, index) of selectedEquipment.imagesReal"
                      :key="image.imageId"
                  >
                    <v-layout justify-space-between class="mb-2" row wrap>
                      <v-flex class="pl-5" md2 v-if="image.image ">
                        <v-img
                            v-if="!selectedEquipment.equipmentId || !image.equipmentId"
                            class="mb-2"
                            :src="image.url"
                            width="55"
                            height="55"
                        >
                        </v-img>
                        <v-img
                            v-else
                            class="mb-2"
                            :src="appendBaseUrl(image.image)"
                            width="55"
                            height="55"
                        ></v-img>
                      </v-flex>
                    </v-layout>
                    <v-layout class="text--left" row wrap>
                      <v-flex md3>
                        <v-btn
                            class="mt-0 pt-0"
                            small
                            icon
                            flat
                            @click="
                            removeImageFromList(
                              image.imageId ? image.imageId : '',
                              index,
                              image
                            )
                          "
                        >
                          <v-icon color="primary" small>delete</v-icon>
                        </v-btn>
                      </v-flex>
                      <v-flex class="my-0 py-0 pt-1" md9>
                        {{ formatImageName(image.image) }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-btn
                    color="primary"
                    class="normalize-font"
                    flat
                    @click="pickFile(false)"
                >
                  <v-icon small>attach_file</v-icon>
                  Produktbilder hinzufügen
                </v-btn>
                <input
                    type="file"
                    style="display: none"
                    ref="images"
                    accept="image/*"
                    @change="onMultipleFilesPicked"
                    multiple
                />
              </v-flex>
            </v-layout>
            <!-- Line battery && charger & others -->
            <v-textarea
                v-model="selectedEquipment.battery"
                label="Batterie"
            ></v-textarea>
            <v-textarea
                v-model="selectedEquipment.charger"
                label="Ladegerät"
            ></v-textarea>
            <!-- <v-textarea
              v-model="selectedEquipment.other"
              label="Sonstiges"
            ></v-textarea>-->
            <v-label>Sonstiges</v-label>
            <v-divider></v-divider>
            <vue-editor
                v-model="selectedEquipment.other"
                :editor-toolbar="[['bold', 'italic', 'underline']]"
            ></vue-editor>
          </v-form>
        </v-card-text>
        <div style="flex: 1 1 auto;"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="normalize-font ml-2 mb-2" flat @click.native="cancel">
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              class="mr-2 mb-2 normalize-font"
              v-if="!selectedEquipment.equipmentId"
              @click.native="createEquipment"
          >
            Erstellen
          </v-btn>
          <v-btn
              color="primary"
              class="mr-2 mb-2 normalize-font"
              v-else
              @click.native="patchEquipment"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card v-if="!isAdmin" tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title class="linde-font px-3">
            {{ selectedEquipment.type }}
            <span v-if="selectedEquipment.constructionType">- </span>
            {{ selectedEquipment.constructionType }}
          </v-toolbar-title>
        </v-toolbar>
        <SellerReadOnlyEquipment :selected-equipment="selectedEquipment"></SellerReadOnlyEquipment>
        <div style="flex: 1 1 auto;"></div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="normalize-font ml-2 mb-2"
              flat
              @click.native="setDialogStatus"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {EventBus} from "../event-bus";

import {VueEditor} from "vue2-editor";

import EquipmentServices from "../services/equipment.services";
import ImageServices from "../services/image.service";

import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import EquipmentImages from "@/components/EquipmentImages.vue";

import CONSTSANTS from "../utils/constants";
import HelperFunctions from "@/utils/helper-functions";
import SellerReadOnlyEquipment from "@/components/SellerReadOnlyEquipment.vue";

let fds = new FormData(),
    fDs = [];

export default {
  name: "EquipmentForm",
  data() {
    return {
      valid: false,
      customImageMaxSize: 5,
      imageName: "",
      imageFile: "",
      imageRealNames: [],
      imagesFiles: [],
      images: [],
      image: "",
      deleteQueue: [],
      isAdmin: false,
      dialog: false,
      constants: CONSTSANTS,
      availability: CONSTSANTS.availibility,
      selectedEquipment: {
        additionalHydraulic: [],
        additionalConstructionType: "",
        attachmentUnit: [],
        availability: "",
        battery: "",
        chassis: [],
        charger: "",
        color: [],
        component: "",
        constructionType: "",
        connectAccessControl: [],
        constructionYear: "",
        deviceStatus: "",
        driversModule: [],
        driversPositionEquipment: [],
        driversSeat: "",
        energyPack: [],
        forkCarrier: [],
        forkCarriageLength: null,
        forkCarriageThickness: null,
        forkOutsideDistance: null,
        freeLift: "",
        guarantee: "",
        heightOfCabin: null,
        height: null,
        initialHub: false,
        isFundable: false,
        image: "",
        imagesReal: [],
        liftingHeight: null,
        loadCapacity: null,
        light: [],
        location: "",
        mastType: "",
        merchantCode: "",
        operatingHours: "",
        operation: "",
        operationAndPedal: [],
        other: "",
        price: null,
        propulsion: "",
        providerProductNumber: "",
        sampleImage: false,
        safety: [],
        serialNumber: "",
        surrounding: [],
        speedControl: [],
        tires: [],
        tradeIn: false,
        trashed: false,
        type: ""
      },
      response: "",
      infoColor: "primary",
      rules: {
        required: value => !!value || "Bitte geben Sie einen Wert ein."
      }
    };
  },
  mounted() {
    this.getConstructionYearsSpan();
    this.isAdmin = this.user.isAdmin;
    EventBus.$on("openDialog", this.setDialogStatus);
    EventBus.$on("onSelectEquipment", equipment => {
      this.loading(true);
      ImageServices.getImagesByEquipmentId(this.$store.state.accessToken, equipment.equipmentId
      ).then(response => {
        if (response.data.length > 0) {
          this.images = response.data.filter((img, index) => {
            return index >= 0;
          });
        }
        this.loading( false);
      });

      equipment.driversModule = this.checkValueForNonEmptyArray(
          equipment.driversModule
      );
      equipment.additionalHydraulic = this.checkValueForNonEmptyArray(
          equipment.additionalHydraulic
      );
      equipment.driversPositionEquipment = this.checkValueForNonEmptyArray(
          equipment.driversPositionEquipment
      );

      equipment.operationAndPedal = this.checkValueForNonEmptyArray(
          equipment.operationAndPedal
      );
      equipment.safety = this.checkValueForNonEmptyArray(this.removeDuplicatesInArray(equipment.safety));
      equipment.light = this.checkValueForNonEmptyArray(equipment.light);
      equipment.energyPack = this.checkValueForNonEmptyArray(
          equipment.energyPack
      );
      equipment.surrounding = this.checkValueForNonEmptyArray(
          equipment.surrounding
      );
      equipment.attachmentUnit = this.checkValueForNonEmptyArray(
          equipment.attachmentUnit
      );
      equipment.tires = this.checkValueForNonEmptyArray(equipment.tires);
      equipment.forkCarrier = this.checkValueForNonEmptyArray(equipment.forkCarrier);
      equipment.speedControl = this.checkValueForNonEmptyArray(equipment.speedControl);
      equipment.color = this.checkValueForNonEmptyArray(equipment.color);
      equipment.connectAccessControl = this.checkValueForNonEmptyArray(equipment.connectAccessControl);
      equipment.chassis = this.checkValueForNonEmptyArray(equipment.chassis);
      equipment.driversSeat = this.checkValueForNonEmptyArray(equipment.driversSeat);

      if (equipment.light) {
        this.formatLightsInformation(equipment.light, 'read');
      }

      this.setSelectedEquipment(equipment);
    });
  },
  methods: {
    ...mapActions(["getEquipmentData", "getConstructionYearsSpan"]),
    ...HelperFunctions,
    pickFile(single) {
      if (single) this.$refs.image.click();
      else this.$refs.images.click();
    },
    onMultipleFilesPicked(e) {
      const files = e.target.files;
      for (let i = 0; i < files.length; i++) {
        fDs.push({file: files[i], name: files[i]["name"]});
      }
      if (files[0] !== undefined) {
        for (const file of files) {
          this.imageRealNames.push(file.name);
          let i = {
            image: file.name,
            url: URL.createObjectURL(file)
          };
          this.selectedEquipment.imagesReal.push(i);
          if (file.name.lastIndexOf(".") <= 0) {
            return;
          }
        }
      } else {
        this.images = [];
        this.imageRealNames = [];
      }
    },
    patchEquipment: function (e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        delete this.selectedEquipment.createdAt;
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;

        // eslint-disable-next-line
        // this.selectedEquipment.image = null;
        this.selectedEquipment.imagesReal = null;
        this.loading( true);
        this.selectedEquipment.updatedAt = new Date(Date.now());
        if (this.selectedEquipment.light) {
          this.selectedEquipment.light = this.formatLightsInformation(this.selectedEquipment.light, '');
        }
        EquipmentServices.patchEquipmentById(
            this.accessToken,
            this.selectedEquipment.equipmentId,
            this.selectedEquipment
        )
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.equipmentId, fDs[i].file, fDs[i].name, "equipment");
                }
              }
            })
            .then(() => {
              this.deleteQueue.forEach(item => {
                // remove all unused images
                const image = this.images.find(i => i.imageId === item);
                // ImageServices.deleteImageById(auth, item);
                ImageServices.deleteEquipmentImage(auth, image.equipmentId, item);
              });
              this.setDialogStatus();
            })
            .then(() => {
              this.initiateSuccessInfo("Equipment update erfolgreich", true)
              this.reloadEquipmentsWithDelay(2000)
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte kontrollieren!")
            });
      } else {
        this.initiateErrorInfo("Bitte kontrollieren!")
      }
    },
    createEquipment(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const auth = this.$store.state.accessToken
            ? this.$store.state.accessToken.id
            : null;
        // eslint-disable-next-line
        this.selectedEquipment.image = null;
        this.selectedEquipment.imagesReal = null;
        this.selectedEquipment.isFundable = false;
        this.selectedEquipment.trashed = false;
        this.selectedEquipment.active = 0;
        this.loading(true);
        this.selectedEquipment.AProdID = this.selectedEquipment.providerProductNumber;
        this.selectedEquipment.location =
            this.selectedEquipment.location == ""
                ? "UTS Center, Bollenheide 4, 42781 Haan"
                : this.selectedEquipment.location;
        this.selectedEquipment.light = this.formatLightsInformation(this.selectedEquipment.light, '');
        EquipmentServices.addEquipment(this.accessToken, this.selectedEquipment)
            .then(response => {
              if (fDs.length > 0) {
                for (let i = 0; i < fDs.length; i++) {
                  fds.append("images", fDs[i].file, fDs[i].name);
                  this.resizeImageAndUpload(auth, response.data.equipmentId, fDs[i].file, fDs[i].name, "equipment");
                }
              }
              this.setDialogStatus();
            })
            .then(() => {
              this.initiateSuccessInfo("Equipment wurde erstellt", true)
              this.reloadEquipmentsWithDelay(3500)
            })
            .catch(() => {
              this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
            });
      } else {
        this.initiateErrorInfo("Bitte Eingaben kontrollieren!")
      }
    },
    setDialogStatus() {
      this.deleteQueue = [];
      this.dialog = !this.dialog;
    },
    cancel() {
      this.resetForm();
      this.resetValidation();
      this.setDialogStatus();
    },
    setSelectedEquipment(equipment) {
      fds = new FormData();
      fDs = [];
      this.selectedEquipment = "";
      this.selectedEquipment = equipment;
      this.getEquipmentData();
    },
    removeImageFromList(imageId, index, name) {
      if (imageId) {
        this.deleteQueue.push(imageId);
      }

      const indexToRemove = fDs.findIndex(img => img.name === name.image);
      fDs.splice(indexToRemove, 1);
      this.selectedEquipment.imagesReal.splice(index, 1);
    },
    removeProductImage(imageId) {
      if (imageId) {
        this.deleteQueue.push(imageId);
      }
      this.imageName = "";
      this.imageFile = "";
      this.selectedEquipment.image = "";
    },
    resetForm() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    ...mapState([
      "constructionYearSpan",
      "constructionTypes",
      "accessToken",
      "user"
    ]),
    constructionYear: {
      get: function () {
        if (this.selectedEquipment.constructionYear)
          return Number(this.selectedEquipment.constructionYear);
      },
      set: function (newValue) {
        this.selectedEquipment.constructionYear = newValue;
      }
    }
  },
  components: {
    SellerReadOnlyEquipment,
    DescriptionValueDisplay,
    EquipmentImages,
    VueEditor,
  }
};
</script>

<style>
#equipment-form .v-chip,
#equipment-form .v-chip__content {
  color: white;
  background: #b80c1e;
}

#equipment-form .v-chip {
  background: #b80c1e;
}
</style>
