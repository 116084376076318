<template>
  <div class="inquiry-management">
    <v-data-table
        style="padding: 10px !important;"
        :headers="headers"
        :items="inquiries"
        :rows-per-page-items="[5,10,25, {'text': 'Alle', 'value':-1}]"
        rows-per-page-text="Zeilen pro Seite"
        class="fixed-actions-table"

    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{
            mapStatus(props.item.status)
          }}
        </td>
        <td class="text-xs-left">{{
            props.item.logDate | formatDate
          }}
        </td>
        <td class="text-xs-left">{{ props.item.salutation }}</td>
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.lastname }}</td>
        <td class="text-xs-left">{{ props.item.address }}</td>
        <td class="text-xs-left">{{ props.item.phone }}</td>
        <td class="text-xs-left">{{ props.item.email }}</td>
        <td class="text-xs-left actions-cell">
          <v-btn right icon small flat color="primary" @click="showInquiry(props.item.id)">
            <v-icon>notes</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <!-- inquiry modal -->
    <InquiryInformation></InquiryInformation>
  </div>
</template>

<script>
import InquiryInformation from "@/components/InquiryInformation.vue";
import InquiriesServices from "../../services/inquiry.services";
import {EventBus} from "../../event-bus";
import HelperFunctions from "@/utils/helper-functions";
import {mapState} from "vuex";

export default {
  name: "inquiry-management",
  data() {
    return {
      showInquiryForm: false,
      headers: [
        {
          text: "Status", value: "status",
          sortable: true,
        },
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "logDate"
        },
        {text: "Anrede", value: "salutation"},
        {text: "Vorname", value: "name"},
        {text: "Nachname", value: "lastname", sortable: true},
        {text: "Adresse", value: "address"},
        {text: "Telefon", value: "phone"},
        {text: "E-Mail", value: "email"},
        {text: "", value: "spacer1"}
      ],
      inquiries: [],
    };
  },
  components: {
    InquiryInformation
  },
  mounted() {
    InquiriesServices.getInquiries(this.accessToken, {})
        .then(response => {
            this.inquiries = response.data;
          })
              .catch(error => {
                throw error;
              });
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    showInquiry(inquiryId) {
      InquiriesServices.getInquiriesById(this.accessToken, inquiryId)
          .then(response => {
            EventBus.$emit("openInquiryDialog", true);
            EventBus.$emit("onSelectInquiry", response.data);
            EventBus.$on("onCloseInquiryDialog", () => {
              this.getInquiries();
            });
          })
    },
    getInquiries() {
      InquiriesServices.getInquiries(this.accessToken)
          .then(response => {
            this.inquiries = response.data;
          })
          .catch(error => {
            throw error;
          });
    }
  }
};
</script>

<style scoped>
</style>
