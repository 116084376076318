<template>
  <v-container class="pa-0 ma-0 mr-0" style="max-width:100%;">
    <v-layout id="equipment-manager">
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-layout text-xs-right>
          <v-flex xs12 class="pa-0 ma-2 mr-0">
            <v-btn
                small
                dark
                fab
                top
                icon
                right
                color="primary"
                @click="onClickAddContactPerson"
            >
              <v-icon style="display: inline-flex">add</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 class="pa-0 ma-0 mr-0">
        <v-data-table
            style="padding: 10px !important;"
            witdth
            :headers="headers"
            :items="contactPersons"
            item-key="id"
            :pagination.sync="pagination"
            :rows-per-page-items="[5, 10, 25, { text: 'Alle', value: -1 }]"
            rows-per-page-text="Zeilen pro Seite"
            class="elevation-0 pa-0 ma-0"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>{{ props.item.id }}</td>
              <td class="text-xs-left">{{ props.item.firstName }}</td>
              <td class="text-xs-left">{{ props.item.lastName }}</td>
              <td class="text-xs-left">
                {{ props.item.careerLevel }}
              </td>
              <td class="text-xs-left">{{ props.item.email }}</td>
              <td class="text-xs-left">{{ props.item.phone }}</td>
              <td class="text-xs-left">{{ props.item.mobilePhone }}</td>
              <td class="text-xs-left">{{ props.item.zip }}</td>
              <td class="actions-cell">
                <v-layout>
                  <v-flex class="px-0 mx-0">
                    <v-btn
                        right
                        small
                        flat
                        color="primary"
                        icon
                        @click="onClickEditContactPerson(props.item)"
                    >
                      <v-icon small>edit</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex class="px-0 mx-0">
                    <v-btn
                        small
                        flat
                        icon
                        @click="openDeleteDialog(props.item)"
                        color="error"
                        dark
                    >
                      <v-icon small>delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </td>
              <td class="text-xs-left"></td>
              <td class="text-xs-left"></td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title class="linde-font px-3"
              >Ansprechpartner löschen</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text>
              Möchten Sie wirklich {{ deleteItem.firstName }}
              {{ deleteItem.lastName }} löschen?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="normalize-font mb-2"
                  flat
                  @click="deleteDialog = false"
              >
                Abbrechen
              </v-btn>
              <v-btn
                  color="primary"
                  class="normalize-font mb-2 mr-2"
                  @click="onDeleteContactPerson(deleteItem.id)"
              >
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <ContactPersonForm
            v-model="dialog"
            :showDialog="dialog"
        ></ContactPersonForm>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import constants from "@/utils/constants";
import ContactPersonService from "@/services/contact-person.service";
import {EventBus} from "@/event-bus";
import EquipmentForm from "@/components/EquipmentForm.vue";
import ContactPersonForm from "@/components/ContactPersonForm.vue";
import ImageService from "@/services/image.service";
import HelperFunctions from "@/utils/helper-functions";
import ImageServices from "@/services/image.service";
export default {
  name: "ContactPersonManagement",
  components: {ContactPersonForm, EquipmentForm},
  data() {
    return {
      pagination: {
        sortBy: "id"
      },
      rules: constants.formRules,
      valid: false,
      validEdited: false,
      dialogStatus: false,
      deleteDialog: false,
      deleteItem: {},
      newUserDialog: false,
      editUserDialog: false,
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Vorname", value: "firstName" },
        { text: "Nachname", value: "lastName" },
        { text: "Role", value: "careerLevel" },
        { text: "E-Mail", value: "email" },
        { text: "Telefon", value: "phone" },
        { text: "Mobil", value: "mobilePhone" },
        { text: "PLZ-Bereich", value: "zip" },
        { text: "", value: "spacer1", sortable: false },
        { text: "", value: "spacer2", sortable: false },
        { text: "", value: "spacer3", sortable: false }
      ],
      contactPersons: [],
    };
  },
  mounted() {
    EventBus.$on("closeCPDialog", () => this.getContactPersons())
    this.getContactPersons()
  },
  computed: {
    ...mapState(["accessToken"]),
    dialog: {
      get: function () {
        return this.dialogStatus;
      },
      set: function (value) {
        this.dialogStatus = value;
      }
    }
  },
  methods: {
    ...HelperFunctions,
    getContactPersons() {
      ContactPersonService.getContactPeople(this.accessToken)
          .then(response => {
            this.contactPersons = response.data;
          })
          .catch(error => {
            throw error;
          });
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    openDeleteDialog(item) {
      this.deleteItem = item;
      this.deleteDialog = true;
    },
    onClickAddContactPerson() {
      EventBus.$emit("openCPDialog", true);
      EventBus.$emit("onSelectContactPerson", {cp: this.getEmptyContactPerson(), operation: "add"});
    },
    onClickEditContactPerson(cp) {
      ImageService.getImagesByContactPersonId(this.accessToken, cp.id)
          .then(response => {
            cp.image = response.data[0]
            this.loading(false);
            EventBus.$emit("openCPDialog", true);
            EventBus.$emit("onSelectContactPerson", {cp, operation: "edit"});
          })
    },
    onDeleteContactPerson(id) {
      ImageService.getImagesByContactPersonId(this.auth, id)
          .then((response) => {
            const image = response.data[0]
            ContactPersonService.deleteContactPerson(this.auth, id)
                .then(response => {
                  if (response.status == 204) {
                    setTimeout(this.setDialogStatus, 200);
                    ImageServices.deleteImageById(this.accessToken, image.imageId);
                  }
                })
                .then(() => {
                  this.initiateSuccessInfo("Ansprechpartner wurde erfolgreich gelöscht", true)
                  this.reloadEquipmentsWithDelay(1500)
                  // hide delete dialog
                  this.deleteDialog = false;
                  this.getContactPersons()
                })
                .catch(() => {
                  this.initiateErrorInfo("Ein Fehler ist aufgetreten!")
                });
          })
    }
  }
};

</script>

<style scoped></style>
