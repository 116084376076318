<template>
  <div class="search-inquiry-management">
    <v-data-table
        style="padding: 10px !important;"
        :headers="headers"
        :items="searchInquiries"
        :rows-per-page-items="[5,10,25, {'text': 'Alle', 'value':-1}]"
        rows-per-page-text="Zeilen pro Seite"
        class=""

    >
      <template slot="items" slot-scope="props">
        <td class="text-xs-left">{{ mapStatus(props.item.status) }}</td>
        <td class="text-xs-left">{{
            props.item.createdAt | formatDate
          }}
        </td>
        <td class="text-xs-left">{{ props.item.companyOrganization }}</td>
        <td class="text-xs-left">{{ props.item.name }}</td>
        <td class="text-xs-left">{{ props.item.address ? props.item.address+", " + props.item.zipCode + " " + props.item.place: "-" }}</td>
        <td class="text-xs-left">{{ props.item.phone }}</td>
        <td class="text-xs-left">{{ props.item.email }}</td>
        <td class="text-xs-left actions-cell">
          <v-btn right icon small flat color="primary" @click="showSearchInquiry(props.item.id)">
            <v-icon>notes</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>

    <!-- inquiry modal -->
    <SearchInquiryInformation></SearchInquiryInformation>
  </div>
</template>

<script>
import SearchInquiryInformation from "@/components/SearchInquiryInformation";
import SearchInquiriesServices from "../../services/search-inquiry.service";
import {EventBus} from "../../event-bus";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "SearchInquiryManagement",
  data() {
    return {
      showInquiryForm: false,
      headers: [
        {
          text: "Status", value: "status",
          sortable: true,
        },
        {
          text: "Datum",
          align: "left",
          sortable: true,
          value: "createdAt"
        },
        {text: "Firma", value: "companyOrganization"},
        {text: "Vor- & Nachname", value: "name"},
        {text: "Adresse", value: "address"},
        {text: "Telefon", value: "phone"},
        {text: "E-Mail", value: "email"},
        {text: "", value: "spacer1"}
      ],
      searchInquiries: [],
      selectedSearchInquiry: ""
    };
  },
  components: {
    SearchInquiryInformation
  },
  mounted() {
    this.getSearchInquiries();
  },
  methods: {
    ...HelperFunctions,
    showSearchInquiry(searchInquiryId) {
      this.selectedSearchInquiry = this.searchInquiries.find(si => si.id === searchInquiryId);
      EventBus.$emit("openSearchInquiryDialog", true);
      EventBus.$emit("onSelectSearchInquiry", this.selectedSearchInquiry);
      EventBus.$on("onCloseSearchInformationDialog", () => {
        this.getSearchInquiries();
      });
    },
    getSearchInquiries() {
      SearchInquiriesServices.getSearchInquiries(
          (this.$store.state.accessToken
              ? this.$store.state.accessToken.id
              : null)
      )
          .then(response => {
            this.searchInquiries = response.data;
          })
          .catch(error => {
            throw error;
          });
    }
  }
};
</script>

<style scoped>
</style>
