<template>
  <div class="home">
    <v-layout class="pt-0 mt-0 mb-2" wrap justify-center row>
      <v-flex md12 sm12 xs12>
        <SearchForm>
          <span
            class="font-weight-medium"
            style="color:#AA0020; font-family: linde;"
          >
            <b>
              Produktkategorie
            </b>
          </span>
        </SearchForm>
      </v-flex>
    </v-layout>

    <v-layout id="vorteile" class="mt-2" wrap justify-space-between row>
      <v-flex md12>
        <ContentBox
          title="Ihre Vorteile - Geprüfte Gebrauchtstapler von Linde Material Handling Rhein-Ruhr"
          :toolbar="true"
          text="Geprüfte Gebrauchtstapler sind für bestimmte Unternehmen nicht nur eine Alternative, sondern die erste Wahl. Je nach den genauen Anforderungen für die jeweilige Unternehmensphase oder der Zusammensetzung der Bestandsflotte bieten geprüfte Gebrauchtstapler relevante Vorteile. Hierzu gehören unter anderem:"
        >
          <v-layout wrap justify-space-between row>
            <v-flex md4>
              <span class="title" style="font-family: linde; color: #AA0020;"
                >QUALITÄT</span
              >
              <p class="mt-2">
                Die Wiederaufbereitung von Flurförderzeugen wird in speziellen
                Aufarbeitungszentren in ganz Europa nach standardisierten
                Prozessen durchgeführt. Diese erfolgt nach strengen
                Qualitätsrichtlinien. Nach der Demontage des Fahrzeugs werden
                alle Teile geprüft, repariert oder ausgetauscht. Auf diese Weise
                stellen wir sicher, dass die von Ihnen bestellten
                Flurförderzeuge immer die höchsten Qualitätsstandards erfüllen.
              </p>
            </v-flex>
            <v-flex md4>
              <span class="title" style="font-family: linde; color: #AA0020;"
                >INDIVIDUELLE AUSSTATTUNG</span
              >
              <p class="mt-2">
                Sicherheit, Ergonomie und Energieverbrauch spielen eine
                entscheidende Rolle für die Optimierung der Effizienz und
                Produktivität Ihrer Arbeitsabläufe. Mit unseren geprüften
                Gebrauchtstaplern können Sie eine große Anzahl modularer Pakete
                frei miteinander kombinieren, um die wichtigsten Funktionen
                Ihres Fahrzeugs zu verbessern. Die Pakete basieren auf dem
                aktuellen Stand der Linde-Technologie, die auch für die neusten
                Fahrzeugmodelle eingesetzt wird.
              </p>
            </v-flex>
            <v-flex md4>
              <span class="title" style="font-family: linde; color: #AA0020;"
                >VERFÜGBARKEIT</span
              >
              <p class="mt-2">
                Wir ermöglichen Ihnen die Flexibilität, genau dann einen Stapler
                zu kaufen, wenn Sie ihn brauchen. Sie können Ihren
                Gebrauchtstapler aus einer breiten Palette von Modellen
                auswählen und individuell anpassen. Da wir immer eine große
                Anzahl von aufbereiteten Fahrzeugen auf Lager haben, können wir
                Ihre Bestellung schnell konfigurieren und zu einem für Sie
                passenden Zeitpunkt an Ihren Standort liefern.
              </p>
            </v-flex>
            <v-flex md4>
              <span class="title" style="font-family: linde; color: #AA0020;"
                >NACHHALTIGKEIT</span
              >
              <p class="mt-2">
                Ihre Entscheidung für einen geprüften Gebrauchtstapler von Linde
                ist ein wichtiger Beitrag für eine verantwortungsvolle Nutzung
                von Ressourcen. Durch die Aufbereitung werden insgesamt weniger
                Rohstoffe verbraucht und der ökologische Fußabdruck des Produkts
                wird erheblich reduziert. Unser standardisierter
                Aufbereitungsprozess erhöht die Effizienz der Lebensdauer und
                erfüllt höchste Umwelt- und Sicherheitsstandards.
              </p>
            </v-flex>
            <v-flex md4>
              <span class="title" style="font-family: linde; color: #AA0020;"
                >LEISTUNG</span
              >
              <p class="mt-2">
                It´s a Linde – unsere geprüften Gebrauchtstapler verkörpern die
                Grundwerte der Marke Linde wie Leistung, Effizienz,
                Benutzerfreundlichkeit, Vielseitigkeit und Zuverlässigkeit. Wir
                überholen alle gebrauchten Geräte nach denselben strengen
                Standards, die für alle Linde-Stapler gelten. Dies gewährleistet
                eine starke Leistung in jeder Umgebung - sei es als Einzellösung
                oder als Teil einer größeren Flotte.
              </p>
            </v-flex>
            <v-flex md4>
            </v-flex>
          </v-layout>
        </ContentBox>
      </v-flex>
      <v-flex> </v-flex>
    </v-layout>

    <v-layout class="mt-0" wrap justify-space-between row>
      <v-flex md12>
        <ContentBox
          id="geprueft"
          title="Linde Approved Standard"
          :toolbar="true"
        >
          <v-layout wrap justify-space-between row>
            <v-flex md4>
              <ul>
                <li>Stapler gemäß Herstellerempfehlungen gewartet und geprüft
                </li>
                <li>Gut erhaltener Fahrersitz und Sicherheitsgurt</li>
                <li>Hubgerüst, Gabelträger und Hydrauliksystem nach
                  Herstellervorgaben geprüft und gewartet
                </li>
                <li>Gabelzinken und Hubketten mit einer maximalen Verschleißgrenze
                  von 50 %
                </li>
                <li>Hydrostatisches Aggregat oder Kombi-Achse nach
                  Herstellerempfehlungen geprüft und gewartet
                </li>
              </ul>
            </v-flex>
            <v-flex md4>
              <span
                class="title"
                style="font-family: linde; color: #AA0020;"
              ></span>
              <ul>
                <li>Lenkachse nach Herstellervorgaben auf Sicherheit geprüft,
                  getestet und gewartet
                </li>
                <li>Gebrauchsfähige Reifen mit mehr als 50% Profiltiefe</li>
                <li>Emissionstest bei verbrennungsmotorischen Fahrzeugen
                  durchgeführt
                </li>
                <li>Ladegerät getestet und voll funktionsfähig bei
                  Elektrofahrzeugen
                </li>
              </ul>
            </v-flex>
            <v-flex md4>
              <span
                class="title"
                style="font-family: linde; color: #AA0020;"
              ></span>
              <ul>
                <li>Neue Lackierung in original Linde-Farben und neue
                  Linde-Aufkleber
                </li>
                <li>Geprüfter Betriebsstunden-Zähler</li>
                <li>Bedienungsanleitung</li>
                <li>Transparente und dokumentierte Staplerhistorie</li>
                <li>FEM 4.004 Prüfung neu erstellt incl. Prüfprotokoll</li>
                <li>Gewährleistung 6 Monate bzw. 500 Betriebsstunden (es gilt der
                  zuerst erreichte Wert)
                </li>
              </ul>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex>
              <v-btn
                class="mt-3 ma-0 py-0 pl-2 pr-2 normalize-font"
                color="primary"
                flat
                small
                href="https://staplerscout24.de/20201216_LMH_Approved_Trucks_Brochure_DE_view.pdf"
                target="_blank"
              >
                <v-icon name="fa-thumbs-up" class="mr-1 ma-0 pa-0" small
                  >download<span class="material-icons">
                    download
                  </span></v-icon
                >
                Broschüre herunterladen
              </v-btn>
            </v-flex>
          </v-layout>
        </ContentBox>
      </v-flex>
    </v-layout>
    <v-layout id="fstStandard" class="mt-2" wrap justify-space-between row>
      <v-flex md12>
        <ContentBox
            title="LMH - RR FST Standard"
            :toolbar="true"
        >
          <v-layout wrap justify-space-between row>
            <v-flex md4>
              <ul>
                <li style="width: 100%">FEM 4.004 geprüft </li>
                <li>Funktionsfähige Batterie</li>
               </ul>
            </v-flex>
            <v-flex md4>
              <ul>
                <li>Gut erhaltener Fahrersitz und Sicherheitsgurt
                </li>
                <li>Guter fachlicher Zustand
                </li>
              </ul>
            </v-flex>
            <v-flex md4>
              <ul>
                <li>Keine Gewährleistung
                </li>
              </ul>
            </v-flex>
          </v-layout>
        </ContentBox>
      </v-flex>
      <v-flex> </v-flex>
    </v-layout>

    <v-layout id="kontakt" wrap justify-space-between row>
      <v-flex sm12 xs12 md12>
        <v-toolbar dark color="primary px-3">
          <v-toolbar-title>Ihre Ansprechpartner</v-toolbar-title>
        </v-toolbar>
      </v-flex>
      <v-flex sm6 xs12 md3>
        <v-card height="100%">
          <v-img
              :src="require('@/assets/contact-person-images/Tim Rodloff.jpg')"
          ></v-img>
          <v-card-text>
              <span class="contact-name">Tim Rodloff</span><br>
              <span class="contact-role">Used Trucks Sales</span>
            <DescriptionValueDisplay
                icon="phone"
                value="02301 912 510"
            ></DescriptionValueDisplay>
            <DescriptionValueDisplay
                icon="alternate_email"
                value="tim.rodloff@lmh-rr.de"
            ></DescriptionValueDisplay>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm6 xs12 md3>
        <v-card height="100%">
          <v-img
              :src="require('@/assets/contact-person-images/Benjamin Buding.jpg')"
          ></v-img>
          <v-card-text>
            <span class="contact-name">Benjamin Buding</span><br>
            <span class="contact-role">Used Trucks Sales</span>
            <DescriptionValueDisplay
                icon="phone"
                value="02129 345 214"
            ></DescriptionValueDisplay>
            <DescriptionValueDisplay
                icon="alternate_email"
                value="benjamin.buding@lmh-rr.de"
            ></DescriptionValueDisplay>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm6 xs12 md3>
        <v-card height="100%">
          <v-img
              :src="require('@/assets/contact-person-images/Tom Greupner.jpg')"
          ></v-img>
          <v-card-text>
            <span class="contact-name">Tom Greupner</span><br>
            <span class="contact-role">Used Trucks Sales</span>
            <DescriptionValueDisplay
              icon="phone"
              value="02129 345 109"
            ></DescriptionValueDisplay>
            <DescriptionValueDisplay
              icon="alternate_email"
              value="tom.greupner@lmh-rr.de"
            ></DescriptionValueDisplay>
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm6 xs12 md3>
        <v-card height="100%">
          <v-img
              :src="require('@/assets/contact-person-images/Simone Rubens.jpg')"
          ></v-img>
          <v-card-text>
            <span class="contact-name">Simone Rubens</span><br>
            <span class="contact-role">Used Trucks Administration</span>
            <DescriptionValueDisplay
                icon="phone"
                value="02129 345 112"
            ></DescriptionValueDisplay>
            <DescriptionValueDisplay
                icon="alternate_email"
                value="simone.rubens@lmh-rr.de"
            ></DescriptionValueDisplay>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout id="topseller" wrap row class="mt-1 mb-1">
      <v-flex class="hidden-sm-and-up" md12>
        <v-card>
          <v-responsive style="background-color: black; text-align: center">
              <a href="https://www.supralift.com/topseller/Linde-Material-Handling-Rhein-Ruhr-GmbH-Co.-KG-Haan/5fbe4e604b85543a036d206c.html" title="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                 alt="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
              style=" background: black; text-align: center">
                <v-img
                    :src="require('@/assets/supralift-topseller-black.png')"
                    alt="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                    title="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                ></v-img>
              </a>
            </v-responsive>
          <v-card-text>
              <span class="title" style="font-family: linde; color: #AA0020;">Linde Rhein-Ruhr bietet ausgezeichnete Qualität</span>
              <p class="">
                Linde Material Handling Rhein-Ruhr ist prämierter Fachhändler für gebrauchte Gabelstapler und Lagertechnikgeräte. Ausgezeichnet mit dem Topseller Siegel bei Supralift – der Online-Suchmaschine für gebrauchte Stapler und Mietstapler – steht Linde Rhein-Ruhr nachweislich für Qualität und Kundenzufriedenheit.
              </p>
              <v-btn
                  class="ma-0 py-0 normalize-font"
                  style="margin-left: -7px !important"
                  color="primary"
                  flat
                  small
                  href="https://www.supralift.com/topseller/Linde-Material-Handling-Rhein-Ruhr-GmbH-Co.-KG-Haan/5fbe4e604b85543a036d206c.html"
                  target="_blank"
              >
                Mehr erfahren
              </v-btn>
              </v-card-text>
        </v-card>
      </v-flex>

      <v-flex class="hidden-xs-only" md12>
        <v-card>
          <v-layout row space-between wrap>
            <v-flex class="pt-0 pb-0" style="text-align: center;" md4 xs12 sm6>
              <a href="https://www.supralift.com/topseller/Linde-Material-Handling-Rhein-Ruhr-GmbH-Co.-KG-Haan/5fbe4e604b85543a036d206c.html" title="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                 alt="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                 style=" background: black; height: 100%">
                <v-img
                    :src="require('@/assets/supralift-topseller-black.png')"
                    alt="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                    title="Gabelstapler gebraucht – Top Seller – Linde Rhein-Ruhr"
                ></v-img>
              </a>
            </v-flex>
            <v-flex class="" md8 xs12 sm6>
              <div class="title mt-2 mb-1 pl-1 pb-2" style="font-family: linde; color: #AA0020;">Linde Rhein-Ruhr bietet ausgezeichnete Qualität</div>

              <div class="pa-1">Linde Material Handling Rhein-Ruhr ist prämierter Fachhändler für gebrauchte Gabelstapler und Lagertechnikgeräte. Ausgezeichnet mit dem Topseller Siegel bei Supralift – der Online-Suchmaschine für gebrauchte Stapler und Mietstapler – steht Linde Rhein-Ruhr nachweislich für Qualität und Kundenzufriedenheit.
              </div>
              <br />
              <v-btn
                  class="mt-2 ma-0 py-0 pr-2 normalize-font"
                  color="primary"
                  flat
                  small
                  href="https://www.supralift.com/topseller/Linde-Material-Handling-Rhein-Ruhr-GmbH-Co.-KG-Haan/5fbe4e604b85543a036d206c.html"
                  target="_blank"
              >
                Mehr erfahren
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout class="mt-0" wrap justify-space-between row>
      <v-flex md12>
        <video controls controlsList="nodownload" :height="videoSize.height">
          <source :src="video" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import SearchForm from "@/components/SearchForm.vue";
import ContentBox from "@/components/ContentBox.vue";
import DescriptionValueDisplay from "@/components/DescriptionValueDisplay";
import ImageServices from "@/services/image.service";
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      video:
        "https://tools.lmh-rr.de/share/UTS_ApprovedTrucks_Youtube.webm",
      videoSize: {
        height: 250,
        width: ""
      }
    };
  },
  components: {
    SearchForm,
    ContentBox,
    DescriptionValueDisplay
  },
  mounted() {
    ImageServices.getImages();
    this.getEquipmentData();
    const anchor = this.$route.hash;
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor;
      }
    });
  },
  created() {
    //Scrolls to top when view is displayed
    window.scrollTo(0, 0);
  },
  methods: {
    ...mapActions(["getEquipmentData"]),
    resizeHeaderImage() {
      if (window.innerWidth < 500 && window.innerWidth > 450)
        this.videoSize.height = 250;
      else if (window.innerWidth <= 450 && window.innerWidth >= 380)
        this.videoSize.height = 150;
      else if (window.innerWidth < 380) this.this.videoSize.height = 100;
      else this.videoSize.height = 350;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.mt10 {
  margin-top: 10px;
}
a {
  color: #42b983;
}

video {
  width: 100%;
  height: auto;
}

.contact-name {
  color: rgb(170, 0, 32);
}
.contact-role {
  color: #636363;
  font-size: 12px;
}

#geprueft li {
  display: table!important;
}
</style>
