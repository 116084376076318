<template>
  <v-dialog v-model="dialog" max-width="450">
    <v-card>
      <v-toolbar dark color="primary px-3">
        <v-toolbar-title>Return Truck zu Staplerscout kopieren</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="subheading">
          Möchten Sie diesen Return Truck zu Staplerscout hinzufügen?
        </p>
        <span class="subheading linde-color linde-font">
                {{ cType ? cType : "" }} - {{ chassisNumber ? chassisNumber : "" }}
              </span>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="normalize-font mb-2"
            flat
            @click="onCancel"
        >
          Abbrechen
        </v-btn>
        <v-btn
            class="normalize-font mb-2 mr-2"
            color="primary"
            @click="copyToMarketplace()"
        >
          Hinzufügen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import ReturnTruckService from "@/services/return-truck.service";
import {EventBus} from "@/event-bus";
import HelperFunctions from "@/utils/helper-functions";

export default {
  name: "ConfirmCopyToMarketplaceDialog",
  data: function () {
    return {
      dialog: false,
      id: "",
      cType: "",
      operation: "",
      chassisNumber: "",
    }
  },
  props: {},
  mounted() {
    EventBus.$on("openConfirmCopyToMarketplaceReturnTruckDialog", (data) => {
      this.dialog = true
      this.id = data.id
      this.cType = data.cType
      this.chassisNumber = data.chassisNumber
    })
  },
  computed: {
    ...mapState(["accessToken"]),
  },
  methods: {
    ...HelperFunctions,
    copyToMarketplace() {
      this.loading(true);
      ReturnTruckService.copyToMarketplace(this.accessToken, this.id)
          .then(() => {
            this.initiateSuccessInfo("Return Truck wurde zu Staplerscout hinzugefügt.", true)
            this.loading(false);
            EventBus.$emit("onCloseReturnTruckConfirmDialog", true);
            this.onCancel()
          })
          .catch((error) => {
            let msg = "Ein Fehler ist aufgetreten! Bitte versuchen Sie es später erneut.";
            if (error.response.status === 400) {
              msg = "Equipment mit gleicher Seriennummer existiert bereits!";
            }
            this.initiateErrorInfo(msg)
            this.loading(false);
            EventBus.$emit("onCloseReturnTruckConfirmDialog", true);
            this.onCancel()
          });
    },
    onCancel() {
      this.dialog = false
      this.returnTruck = null
      this.id = ""
      this.type = ""
    },
  }
};
</script>
